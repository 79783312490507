import { AnswerResponseBody, QuestionResponseBody } from './response/Types';
import { SwEaseError } from '../common/util';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

export class SwEaseFetchAPI {
  private readonly baseUrl: string;
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(baseUrl: string, discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    this.baseUrl = baseUrl;
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  private getResponse(url: string, body?: any): Promise<Response> {
    return this.fetchApi.fetch(url, {
      method: body ? 'POST' : 'GET',
      body: body ? JSON.stringify(body) : undefined,
      headers: body ? { 'Content-Type': 'application/json' } : undefined,
    } as RequestInit);
  }

  private async getBaseUrl(): Promise<string> {
    const serviceUrl = await this.discoveryApi.getBaseUrl(this.baseUrl);
    return `${serviceUrl}`;
  }

  private async getData(response: Response) {
    return (await response.json()) as QuestionResponseBody | AnswerResponseBody;
  }

  async fetch(api: string, errorMessage: string, body?: any): Promise<any> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}${api}`;
    const response = await this.getResponse(url, body);
    if (response.status === 200) {
      const data = await this.getData(response);
      if ('errors' in data) {
        throw new SwEaseError(errorMessage, data.errors);
      }
      return data;
    }

    throw new SwEaseError(`Request failed for ${api}.`, null);
  }
}
