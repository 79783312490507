import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});

interface Props {
  fillColor: '#0B41CD' | '#FFFFFF';
}
const LogoFull = ({ fillColor }: Props) => {
  const classes = useStyles();

  return (
    <svg
      version="1.1"
      id="Art"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 800 415.8"
      className={classes.svg}
    >
      <g>
        <g>
          <path
            fill={fillColor}
            d="M653.7,183c-3.6-7.4-8.7-13-15.2-17s-14-6-22.4-6s-15.9,2-22.4,6s-11.6,9.7-15.2,17c-3.6,7.4-5.4,16-5.4,25.8
			v22.1c0,9.8,1.8,18.4,5.4,25.8c3.6,7.4,8.7,13,15.2,17s14,5.9,22.4,5.9s15.9-2,22.4-5.9c6.5-4,10.5-8.5,14.1-15.9
			c1.7-3.5,3.2-7.9,4-12.4l-20.6-4.7c-0.9,4.4-2.4,8.6-4.7,11.5c-3.7,4.8-9.1,6.9-15.2,6.9c-6.1,0-11.5-2.2-15.2-6.9
			c-3.7-4.8-5.5-11.6-5.5-20v-8.3h63.8v-15.2C659.1,198.9,657.3,190.4,653.7,183z M616.1,180.5c6.1,0,11.5,2.1,15.2,6.8
			c3.3,4.2,5.1,10.6,5.5,17.9h-41.4c0.3-7.3,2.1-13.7,5.5-17.9C604.5,182.6,609.9,180.5,616.1,180.5z"
          />
          <path
            fill={fillColor}
            d="M494.8,277v-75.4c0-6.3,1.8-11.4,5.3-15.1c3.6-3.7,8-5.6,13.5-5.6c5.4,0,9.9,1.9,13.5,5.6
			c3.6,3.7,5.3,8.7,5.3,15.1V277h22.3v-80.1c0-11.6-3-20.6-9-27.1s-14.2-9.7-24.6-9.7c-5.7,0-10.8,1.1-15.6,3.4
			c-4.7,2.3-8.5,5.3-10.6,8.5v-57.3h-22.3V277H494.8z"
          />
          <path
            fill={fillColor}
            d="M392.8,232c0,8.4,1.9,15.2,5.6,19.9c3.8,4.7,9.2,6.8,15.3,6.8c11,0,17.9-7.1,20.5-21.2l20.7,4.8
			c-1.7,11.8-6,21-12.9,27.5s-16.3,9.8-28.3,9.8c-13,0-23.5-4.4-31.4-13.2c-7.9-8.8-11.9-20.8-11.9-35.8V209c0-15,3.9-27,11.9-35.8
			c7.9-8.8,18.4-13.2,31.4-13.2c12,0,21.4,3.3,28.3,9.8s11.2,15.7,12.9,27.5l-20.7,4.8c-2.6-14.1-9.4-21.2-20.5-21.2
			c-6.1,0-11.6,2.1-15.3,6.8c-3.8,4.7-5.6,11.4-5.6,19.9V232z"
          />
          <path
            fill={fillColor}
            d="M310.3,258.7c-6.1,0-11.5-2.2-15.2-6.9c-3.7-4.8-5.5-11.6-5.5-20v-24.2c0-8.4,1.8-15.2,5.5-19.9
			s9.1-6.8,15.2-6.8s11.5,2.1,15.2,6.8s5.5,11.4,5.5,19.9v24.2c0,8.4-1.8,15.2-5.5,20C321.8,256.6,316.4,258.7,310.3,258.7
			 M310.3,279.7c8.4,0,15.9-2,22.4-5.9c6.5-4,11.6-9.7,15.2-17c3.6-7.4,5.4-16,5.4-25.8v-22.1c0-9.8-1.8-18.4-5.4-25.8
			c-3.6-7.4-8.7-13-15.2-17s-14-6-22.4-6s-15.9,2-22.4,6s-11.6,9.7-15.2,17c-3.6,7.4-5.4,16-5.4,25.8V231c0,9.8,1.8,18.4,5.4,25.8
			c3.6,7.4,8.7,13,15.2,17C294.4,277.7,301.9,279.7,310.3,279.7"
          />
          <path
            fill={fillColor}
            d="M190.9,183.2v-47.3h22.5c6,0,10.7,1.9,14,5.8c3.3,3.9,5,9.8,5,17.9c0,8-1.7,14-5,17.9s-8,5.8-14,5.8
			L190.9,183.2L190.9,183.2z M190.9,277v-72.4H210c5.5,0,9.1,1.3,11.5,3.8s3.7,6.5,4.4,12.2l6.3,56.4h22.4l-6.7-58.5
			c-0.8-7-1.7-11.6-4.5-15.7c-2.6-3.7-6.3-6.8-10.6-8.2c6.8-3.2,12.2-7.8,16.1-13.6c3.9-5.8,5.9-13.8,5.9-24
			c0-13.1-3.7-23.5-11-31.1c-7.4-7.6-17.5-11.4-30.3-11.4h-44.8V277H190.9z"
          />
        </g>
        <path
          fill={fillColor}
          d="M800,207.9L619.3,415.8H180.7L0,207.9L180.7,0h438.5L800,207.9z M609.6,394.7L772,207.9L609.6,21.1H190.3
		L28,207.9l162.3,186.8H609.6z"
        />
      </g>
    </svg>
  );
};

export default LogoFull;
