import { CustomErrorBase } from '@backstage/errors';

export class SwEaseError extends CustomErrorBase {
  public errors: null | undefined | any[];

  constructor(message: string, errors: null | undefined | any[]) {
    super(message);
    this.errors = errors;
  }
}
