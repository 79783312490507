import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { SwEaseAPI } from './SwEaseAPI';

import { Answer, API, PARAM } from '@roche-ease/plugin-sw-ease-common';
import {
  AnswerCommentRequest,
  AnswerRequest,
  GetAnswerRequest,
  QuestionResponse,
  UserOrgProductResponseBody,
} from './response/Types';
import { SwEaseFetchAPI } from './SwEaseFetchAPI';

export const swEaseApiRef = createApiRef<SwEaseAPI>({
  id: 'plugin.sw-ease.api',
});

export class SwEaseClient implements SwEaseAPI {
  private readonly fetchApi: SwEaseFetchAPI;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.fetchApi = new SwEaseFetchAPI(
      'sw-ease-backend',
      options.discoveryApi,
      options.fetchApi,
    );
  }

  async getQuestion(yamlQuestionId: string): Promise<QuestionResponse> {
    const api = API.QUESTION_GET.replace(
      PARAM.YAML_QUESTION_ID,
      yamlQuestionId,
    );

    return this.fetchApi.fetch(
      api,
      `Failed to get question with yamlQuestionId: ${yamlQuestionId}.`,
    );
  }

  async saveAnswer(answer: AnswerRequest): Promise<Answer> {
    return this.fetchApi.fetch(
      API.ANSWER_POST,
      `Failed to save answer for question: ${answer.yaml_question_id}.`,
      answer,
    );
  }

  async updateAnswerComment(comment: AnswerCommentRequest): Promise<Answer> {
    const api = API.ANSWER_COMMENT_POST.replace(
      PARAM.ANSWER_ID,
      comment.answer_id,
    );
    return this.fetchApi.fetch(
      api,
      `Failed to update answer comment for question: ${comment.answer_id}.`,
      comment,
    );
  }

  async getUserOrgProduct(): Promise<UserOrgProductResponseBody> {
    return this.fetchApi.fetch(
      API.ORG_PRODUCT_GET,
      `Failed to get user org product.`,
    );
  }

  async getAnswersByGroup(question: GetAnswerRequest): Promise<Answer[]> {
    return this.fetchApi.fetch(
      API.ANSWER_GET,
      `Failed to get answer for question ${question.yaml_question_ids} based on user session`,
      question,
    );
  }
}
