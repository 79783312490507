import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
} from '@backstage/theme';

export const devHubThemeLight = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.light,
  }),
  defaultPageTheme: 'home',
  typography: {
    htmlFontSize: 16,
    fontFamily: [
      'Roche Sans',
      'Roche Sans Light',
      'Roche Sans Bold',
      'Roche Sans Condensed Light',
    ].join(','),
    h1: {
      fontSize: 54,
      fontWeight: 700,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  components: {
    BackstagePage: {
      styleOverrides: {
        root: {
          background: '#FFF',
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          backgroundImage: 'none',
          background: '#F5F5F2',
        }),
        title: () => ({
          color: '#544F4F',
          fontFamily: 'Roche Sans Bold',
        }),
        subtitle: () => ({
          color: '#706B69',
          fontFamily: 'Roche Sans Condensed Light',
          fontSize: '1.5em',
          marginTop: '0px',
        }),
        rightItemsBox: () => ({}),
        type: {
          color: 'black',
        },
      },
    },
    BackstageSidebar: {
      styleOverrides: {
        drawer: {
          background: '#F5F5F2',
          color: '#544f4f',
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'transparent !important',
            color: '#0B41CD',
          },
        },
        label: {
          color: '#544f4f',
          fontWeight: 'normal',
        },
        selected: {
          color: '#0B41CD !important',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          background: '#F5F5F2',
        },
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          color: 'black',
          backgroundImage:
            'url("https://storage.googleapis.com/devhub-data-prod/news-square.jpg")',
        },
      },
    },
    PluginCatalogEntityContextMenu: {
      styleOverrides: {
        button: {
          color: 'black',
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          color: 'black',
        },
        value: {
          color: 'black',
        },
      },
    },
  },
});

export const devHubThemeDark = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.dark,
  }),
  defaultPageTheme: 'home',
  typography: {
    htmlFontSize: 16,
    fontFamily: [
      'Roche Sans',
      'Roche Sans Light',
      'Roche Sans Bold',
      'Roche Sans Condensed Light',
    ].join(','),
    h1: {
      fontSize: 54,
      fontWeight: 700,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          backgroundImage: 'none',
        }),
        title: () => ({
          color: '#FFF',
          fontFamily: 'Roche Sans Bold',
        }),
        subtitle: () => ({
          fontFamily: 'Roche Sans Condensed Light',
          fontSize: '1.5em',
          marginTop: '0px',
        }),
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'transparent !important',
            color: '#1482FA',
          },
        },
        label: {
          color: '#FFF',
          fontWeight: 'normal',
        },
        selected: {
          color: '#1482FA !important',
        },
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          color: 'black',
          backgroundImage:
            'url("https://storage.googleapis.com/devhub-data-prod/news-square.jpg")',
        },
      },
    },
  },
});
