import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { SwEaseClient, swEaseApiRef } from './api/SwEaseClient';

export const swEasePlugin = createPlugin({
  id: 'sw-ease',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: 'enable-ease-plugin-beta-release' }],
  apis: [
    createApiFactory({
      api: swEaseApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new SwEaseClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const SWEaseHomePage = swEasePlugin.provide(
  createRoutableExtension({
    name: 'SWEaseHomePage',
    component: () => import('./main/App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);
